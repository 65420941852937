import { defineStore } from 'pinia';
import type { IBullet } from '@/models/interface/bullet';
import { ref } from 'vue';

export const useAppStore = defineStore('app', () => {
  const isMobile = ref<boolean | null>(null); //是否為手機
  const device = ref<string>(''); //pc或mobile
  const mobileType = ref<string>(''); //手機類型
  const isLoading = ref<boolean>(false); //是否正在loading
  const isShowEvent = ref(false); // 是否顯示推廣活動
  const adImageSrc = ref<string>(''); // 推廣活動圖片
  const isBulletOpen = ref(false); // 是否開啟佈告欄
  const bulletContent = ref<IBullet[]>([]); // 佈告欄資料
  const settingHomePage = ref<string>('calendar'); //預設首頁為行事曆
  const isShowBeginnerGuide = ref(
    localStorage.getItem('showBeginnerGuide')
      ? JSON.parse(localStorage.getItem('showBeginnerGuide')!)
      : true
  ); // 是否顯示初學者指南
  const isFull = ref(false); //是否開啟sidebarFull
  const isLanding = ref(false); //footer跳轉去login頁看(保持登入狀態)
  const showLottery = ref(true); //是否顯示lottery entry icon(只為了處理listening第一個component要顯示而已)

  /** 是否為移動裝置寬度 */
  // const handleGetCurrentDevice = (): void => {
  //     console.log('[handleGetCurrentDevice]');
  //     isMobile.value = window.innerWidth <= 1024;
  // };

  /** 處理裝置 */
  const handleDetectDevice = () => {
    console.log('[handleDetectDevice]');
    // 用户代理字串
    const userAgent = navigator.userAgent;

    // 是否是手機裝置
    const isMobileDevice = /Mobile|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );

    if (isMobileDevice) {
      device.value = 'mobile';
      if (navigator.userAgent.match(/Android/i)) {
        mobileType.value = 'Android';
      } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        mobileType.value = 'iOS';
      } else {
        mobileType.value = 'Other';
      }
    } else {
      device.value = 'pc';
    }
  };

  return {
    isMobile, //是否為手機
    device, //pc或mobile
    mobileType, //手機類型
    isLoading, //是否正在loading
    isShowEvent,
    isBulletOpen,
    bulletContent,
    adImageSrc,
    settingHomePage,
    isShowBeginnerGuide,
    isFull,
    isLanding,
    showLottery,
    handleDetectDevice
  };
});
