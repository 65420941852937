<script setup lang="ts">
import Loading from './components/loading/Loading.vue';
// import PopupOverlay from '@/components/popup/PopupOverlay.vue';
import Bulletin from '@/components/common/Bulletin.vue';

import { useAppStore } from '@/stores/app';
import { defineAsyncComponent, onMounted } from 'vue';
import { usePopStore } from './stores/pop';

const appStore = useAppStore();
// const showTeachHowToUse = computed(() => {
//   if (localStorage.getItem('teachHowToUse') == null) {
//     localStorage.setItem('teachHowToUse', 'true');
//   }
//   return localStorage.getItem('teachHowToUse');
// });

const fnBulletClosePopup = (val: boolean) => {
  if (val) {
    appStore.isBulletOpen = false;
  }
};

const handleGetCurrentDevice = (): void => {
  window.innerWidth <= 1024 ? (appStore.isMobile = true) : (appStore.isMobile = false);
  appStore.isFull = appStore.isMobile;
};

onMounted(() => {
  appStore.handleDetectDevice();
  handleGetCurrentDevice();
  window.addEventListener('resize', handleGetCurrentDevice);

  const preload = localStorage.getItem('preload');
  window.addEventListener('vite:preloadError', () => {
    if (!preload) {
      localStorage.setItem('preload', JSON.stringify(true));
      setTimeout(() => {
        location.reload();
      }, 300);
    } else {
      localStorage.removeItem('preload');
    }
  });
});
</script>

<template>
  <RouterView />

  <teleport to="body">
    <Loading v-if="usePopStore().loadingStatus"></Loading>
  </teleport>

  <BasePopup :coverArea="'fixed'" v-model:showPopup="appStore.isBulletOpen">
    <Bulletin @close-popup="fnBulletClosePopup"></Bulletin>
  </BasePopup>
</template>

<style lang="scss">
body {
  @include scroll-style();
  color: $txt-color-grey-80;
  font-family: PingFangTC, 微軟正黑體, $font-family-others;
}

@keyframes loader {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.page-main {
  padding-top: $header-h;
  padding-left: $sidebar-w;

  @include media-breakpoint-down(lg) {
    padding-left: inherit;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.grow-enter-active,
.grow-leave-active {
  transition: all 0.5s ease;
}

.grow-enter-from,
.grow-leave-to {
  opacity: 0;
  width: 0;
}

.grow-enter-to,
.grow-leave-from {
  opacity: 1;
  width: 24px;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

/* 從左到右進入 */
.slide-enter-from {
  transform: translateX(100%);
}

.slide-enter-to {
  transform: translateX(0);
}

/* 從左到右離開 */
.slide-leave-from {
  transform: translateX(0);
  // opacity: 0;
}

.slide-leave-to {
  transform: translateX(-100%);
  // opacity: 0;
}

.header-scroll-hidden {
  transform: translateY(-200px);
}

.el-message-box .error-el-msg-box-btn {
  background-color: #f74768 !important;
  border-color: #f74768 !important;
  color: #fff !important;
}
</style>
