import type { RouteLocationNormalized, NavigationGuardNext, Router } from 'vue-router';
import { UtilCommon } from '@/utils/utilCommon';
import { useUserStore } from '@/stores/user';
import { CookiesKeys } from '@/constants/enums/CookiesEnum';

export default async function webview({
  to,
  from,
  next,
  router
}: {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
  router: Router;
}) {
  const user = useUserStore();
  const cookie = UtilCommon.getCookie(CookiesKeys.WebViewToken);

  if (cookie) {
    user.isLogin = true;
    user.token = cookie;
  }

  return next();
}
