import { RoleType, type MemberInfoI } from '@/models/interface/user';

// 會員初始化資料
const initialMemberInfo: MemberInfoI = {
  memberId: 0, // 官網會員ID
  userId: null, // 校園Campus會員ID
  customerId: 0, // B2B ID(大部分會員為411)
  realName: '',
  nickName: '',
  sex: 1,
  birthday: null,
  email: null,
  photo: null, // 大頭貼(連動校園Campus)
  startDate: '',
  endDate: '', // 視訊到期日
  hasLevel: false, // 是否有已預設等級
  point: 0,
  pointDate: null, //學堂到期日
  level: 2, // 大等級(preA1-C2)
  levelStep: 1, // 小等級
  isDemo: false,
  isPay: 0,
  isFreeUser: 0,
  isThirdParty: false,
  roleType: RoleType['notJunior'], // 是否為國中生
  canReserveEarly: false, // 是否可提早預約(VVIP)
  chatIds: {
    emailCreation: '',
    grammarCheck: '',
    grammarExplanation: '',
    persona: [],
    translation: '',
    aiAssistant: ''
  },
  packageInfo: null,
  contractStartDate: null
};

// 各大等級對應數字
const levelsMapping: { [key: string]: number } = {
  PreA1: 0,
  A1: 1,
  A2: 2,
  B1: 3,
  B2: 4,
  C1: 5,
  C2: 6
};

export { initialMemberInfo, levelsMapping };
