import { usePopStore } from '@/stores/pop';
import { useUserStore } from '@/stores/user';

const checkAdIdParam = async () => {
    // console.log('Query ad_id');
    const userStore = useUserStore();
    const baseUrl: string = import.meta.env.VITE_FUNDAY_BASE_URL;

    // find ad_id value in window.location.search
    const searchParams = new URLSearchParams(window.location.search);
    const adId = searchParams.get('ad_id');
    if (adId) {
        userStore.adId = Number(adId);
        const response = await fetch(`${baseUrl}/Advertisement/AdImage?AdId=${adId}`);
        const adImageSrc = await response.json();

        sessionStorage.removeItem('isFromAd');
        sessionStorage.removeItem('adImagePcUrl');
        sessionStorage.removeItem('adImageSrcPhoneUrl');

        sessionStorage.setItem('isFromAd', 'true');
        if (adImageSrc.content && adImageSrc.content.pcUrl) {
            sessionStorage.setItem('adImagePcUrl', adImageSrc.content.pcUrl);
        }
        if (adImageSrc.content && adImageSrc.content.phoneUrl) {
            sessionStorage.setItem('adImageSrcPhoneUrl', adImageSrc.content.phoneUrl);
        }
        usePopStore().loadingStatus = false;
        
        return new Promise((resolve)=>resolve(true));
    } else {
        return new Promise((resolve) => resolve(false));
    }
};

export default checkAdIdParam;
