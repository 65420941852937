import { initialMemberInfo } from '@/constants/user';
import type { MemberInfoI } from '@/models/interface/user';
import { jwtDecode } from 'jwt-decode';
import compareDates from '@/composables/useCompareDate';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { LocalStorageKeys } from '@/constants/enums/LocalStorageEnum';
import cloneDeep from 'lodash';

export const useUserStore = defineStore(
  'user',
  () => {
    const userAccount = ref('');
    const userPassword = ref('');
    const userData = ref<MemberInfoI>(initialMemberInfo);
    const rememberAccountAndPassword = ref(true);
    const earlyReserveOriginalCount = ref(10);

    const forceLogoutMsg = '由於頁面閒置過久，系統已自動登出。'; //token失效
    const unAuthorized = '您重複登入，系統已自動登出。'; //http code 401

    const token = ref('');
    const isLogin = ref(false);
    const isFirstLogin = ref(false);  //第一次登入後要打log的判斷
    const needGetMemberInfo = ref(true);
    const popupFlag = ref(false);

    // TODO: 待調整 => 已新增常數在constant/user.ts, LessonView裡的需拔除
    const levelObj = {
      PreA1: 0,
      A1: 1,
      A2: 2,
      B1: 3,
      B2: 4,
      C1: 5,
      C2: 6
    };

    const adId = ref<number>(0);
    const adImageSrc = ref<{ [key: string]: string }>({
      pcUrl: '',
      phoneUrl: ''
    }); // 推廣活動圖片
    const adRole = ref(''); // 推廣廣告身分別(for EVENT)
    const isAfterSignUp = ref(false); // 是否為註冊後

    const isNewRegisterUser = computed(() => {
      const onlineDate = '2024-06-10'; // For production

      if (
        compareDates(userData.value.startDate, onlineDate, 'after') ||
        compareDates(userData.value.startDate, onlineDate, 'equal')
      ) {
        return true;
      } else {
        return false;
      }
    });

    const fdtk = computed(() => {
      const decodeToken = jwtDecode(token.value);
      return (decodeToken as any).fdtk;
    });

    const social_Notification = ref(false);

    const initUserData = async () => {
      //因為pinia有配置持久化 所以要刪除觸發pinia更新狀態
      localStorage.removeItem(LocalStorageKeys.UserStore);
      initialMemberInfo.memberId = 0;
      userData.value = { ...initialMemberInfo };
      isLogin.value = false;
      needGetMemberInfo.value = true;
      token.value = '';
    };

    return {
      userData,
      social_Notification,
      userAccount,
      userPassword,
      rememberAccountAndPassword,
      token,
      isLogin,
      isFirstLogin,
      needGetMemberInfo,
      levelObj,
      adId,
      adImageSrc,
      adRole,
      isAfterSignUp,
      isNewRegisterUser,
      fdtk,
      popupFlag,
      forceLogoutMsg,
      unAuthorized,
      earlyReserveOriginalCount,
      initUserData
    };
  },
  {
    persist: {
      key: LocalStorageKeys.UserStore,
      storage: localStorage,
      paths: [
        'userAccount',
        'userPassword',
        'rememberAccountAndPassword',
        'token',
        'isLogin',
        'adRole',
        'userData.memberId'
      ]
    }
  }
);
