import type { RouteLocationNormalized, NavigationGuardNext, Router } from 'vue-router';
import { UtilCommon } from '@/utils/utilCommon';
import type { SettingHomePage } from '@/models/interface/storageValue';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import { RoleType } from '@/models/interface/user';
import { SessionStorageKeys } from '@/constants/enums/SessionStorageEnum';
import { CookiesKeys } from '@/constants/enums/CookiesEnum';

export default async function customHomeClass({
  to,
  from,
  next,
  router
}: {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
  router: Router;
}) {
  const app = useAppStore();
  const user = useUserStore();
  const storage = UtilCommon.getLocalStorage<SettingHomePage[]>('customHome');

  // 訂閱頁註冊或登入後，導回訂閱頁
  const subscription = UtilCommon.getCookie(CookiesKeys.Subscription);

  if (subscription) {
    const split = subscription.split('PurchaseKey=');
    const key = split[1] ? encodeURIComponent(split[1]) : '';

    window.location.href = `/newSubscription?${split[0]}PurchaseKey=${key}`;
    return;
  }

  // 訂閱頁購買註冊或登入後，導回購買頁
  const subscriptionPurchase = UtilCommon.getCookie(CookiesKeys.SubscriptionPurchase);
  if (subscriptionPurchase) {
    const split = subscriptionPurchase.split('PurchaseKey=');
    let key = '';
    if (split[1]) {
      const split2 = split[1].split('&');
      key = `${encodeURIComponent(split2[0])}&${split2[1]}`;
    }
    window.location.href = `/newSubscription/orderPlan?${split[0]}PurchaseKey=${key}`;
    return;
  }

  //要改成用cookie(跨分頁)
  // 先判斷是否從訂閱頁的註冊過來,是的話直接導回訂閱頁
  // const subscriptionSignup = sessionStorage.getItem(SessionStorageKeys.SubscriptionSignUp);
  // if (subscriptionSignup) {
  //   window.location.href = '/newSubscription';
  //   return;
  // }

  if (!app.isMobile && user.userData.roleType !== RoleType['isJunior']) {
    const storageValue = storage?.find((item) => item.memberId === user.userData.memberId);

    if (user.isAfterSignUp && !storageValue) {
      window.location.href = '/custom-home-page';
    }
    if (from.path === '/login' && !storageValue) {
      window.location.href = '/custom-home-page';
    }
  }

  return next();
}
